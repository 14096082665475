.landingpage-wrapper > section {
  position: relative;
  line-height: 0;
}

.landingpage-wrapper .jws-grid-container-gutter {
  margin-bottom: 0;
}

.landingpage-wrapper .jws-grid-container-gutter > [class^="jws-grid-"] {
  margin-bottom: 0;
}

picture {
  display: block;
  position: relative;
  line-height: 0;
}

figure {
  margin: 0;
}

.msdw-container {
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 1200px) {
  .msdw-container {
    max-width: 1160px;
  }
}

body {
  margin: 0;
}

.jwsdw-pageWrapper {
  max-width: none;
}
/*# sourceMappingURL=20220831CountryInfopages_styles.css.map */