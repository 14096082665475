
// Base
// Headvisual
// Intro text
// Footer


//
// =Base
// --------------------------------------------------

@import "partials/media-queries";
@import "partials/layout";
// @import "partials/animations";

// @import "partials/slides";
// @import "partials/lint";


body { margin: 0; }

.jwsdw-pageWrapper {
    max-width: none;
}